@import '../../../../Style/typographie.scss';
@import '../../../../Style/breakpoint.scss';

.Hero {
    position: relative;
    height: calc(100svh);
    min-height: 720px;
    padding: 0 0 !important;

    @media (max-width: 900px) {
        width: auto;
        height: 100svh;
        min-height: 500px;
        max-height: 800px;
    }

    .wrapper-video {
        height: 100%;
        width: 100%;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .inner {
        position: absolute;
        inset: 0;
        padding: 80px 64px 120px 64px;
        width: '100%';
        height: '100%';
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: flex-end;

        @media (max-width: 1000px) {
            padding: 80px 24px 120px 24px;
        }

        @media (max-width: 775px) {
            padding: 80px 16px 64px 16px;
        }

        .WrapperSectionTitle {
            margin-bottom: 16px;
        }

        .extra-info {
            flex: 1;
            max-width: 1253px;
            margin: 0 auto;
        }

        .Button {
            margin-top: 32px;
            min-width: 0 !important;
            width: fit-content;

            @media (max-width: 580px) {
                margin-top: 40px;
                width: 264px;
                padding: 12px 50px !important;
            }

            @media (max-width: map-get($breakpoints, 'l')) {
                min-width: 100% !important;
            }
        }
    }
}

.annoucement-toast {
    position: absolute;
    top: 142px;
    left: 0;
    right: 0;
    z-index: 60;
    padding: 0 60px;
    display: flex;

    @media (max-width: 1000px) {
        top: 112px;
        padding: 0 24px;
    }

    @media (max-width: 775px) {
        padding: 0 16px;
    }

    .box {
        display: flex;
        gap: 24px;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        backdrop-filter: blur(25px);
        background-color: #2b2b2b80;
        margin-left: auto;
        margin-right: auto;
        border-radius: 20px;
        flex-basis: 1253px;

        .left {
            text-align: left;
            padding: 24px 32px;

            .whatnews {
                color: white;
            }

            .content {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
                align-items: center;

                @media (max-width: 600px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .learn-more {
                    margin-left: 8px;
                    gap: 8px;
                    text-decoration: underline;
                    font-weight: 700;

                    @media (max-width: 600px) {
                        display: block;
                        margin-left: 0px !important;
                    }
                }

                .wrapper-arrow-right {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    transform: translateY(6px);

                    padding-left: 0 !important;
                    margin-left: 10px !important;

                    @media (max-width: 600px) {
                        margin-left: 6px !important;
                    }
                }
            }
        }

        .right {
            // display: flex;
            padding: 24px 32px;

            @media (max-width: 1000px) {
                margin-bottom: auto;
                padding: 16px 24px 0 0;
            }

            .close {
                background-color: transparent;
                padding: 8px;
                border-radius: 6px;
                transition: all 0.2s ease;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
