.FAQPage {
    margin: 80px 0;

    .hero {
        padding: 80px 16px 32px 16px;
        text-align: center;

        @media (max-width: 768px) {
            padding: 80px 16px 40px 16px;
        }
    }

    .wrapper-faq {
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 0 16px;

        @media (max-width: 850px) {
            gap: 18px;
        }
    }
}
