.dialogContent {
    width: min(100vw, 1364px);
    min-height: 554px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;

    @media (max-width: 1319px) {
        width: 90vw;
    }

    @media (max-width: 1000px) {
        grid-template-columns: auto;
        gap: 24px;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 64px 0px 64px 80px;
        gap: 32px;

        @media (max-width: 1000px) {
            padding: 48px 48px 0px 48px;
        }

        h2 {
            margin-bottom: 16px;
        }

        button {
            align-self: flex-start;
        }
    }

    .right {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        @media (max-width: 1000px) {
            width: 100%;
        }
    }
}
