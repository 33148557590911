@import 'src/Style/theme';

.LayoutFooter {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $surface-grey-disabled;
    margin-top: auto;
    background-color: $white;

    .cursor-pointer {
        svg {
            @media (max-width: 1300px) {
                width: 140px;
            }
        }
    }

    .top-section {
        margin: 45px auto 45px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1280px;
        width: 100%;
        gap: 16px;

        .links-container {
            display: flex;
            align-items: center;
            gap: 20px;
            text-align: center;

            a {
                span {
                    color: $surface-grey-opposite !important;
                }
            }
        }

        .social-media-container {
            display: flex;
            gap: 20px;

            @media (max-width: 915px) {
                max-width: 160px;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }

    .bottom-section {
        background-color: $surface-grey-opposite;
        width: 100%;
        .inner {
            height: 40px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 1280px;
        }
    }

    @media screen and (max-width: 1300px) {
        .top-section,
        .bottom-section .inner {
            margin-left: 20px;
            margin-right: 20px;
            width: auto;
        }
    }

    @media screen and (max-width: 915px) {
        .top-section,
        .links-container,
        .bottom-section .inner {
            flex-direction: column;
            align-items: center;
        }

        .top-section {
            gap: 40px;
            .links-container {
                gap: 40px;
            }
        }

        .bottom-section {
            .inner {
                height: auto;
                margin: 20px 0;
                gap: 20px;
            }
        }
    }

    a {
        text-decoration: none;
        color: $white;
    }

    .social-media-container {
        svg {
            fill: $white;
            width: 24px;
            height: 24px;
        }
    }
}
