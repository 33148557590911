.main {
    width: 100vw;
    padding: 80px 0;

    & * {
        box-sizing: border-box;
    }
}

.container {
    max-width: 540px;
    min-height: calc(100vh - 80px);
    margin: 0 auto;
}

.payment {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    align-items: center;
    gap: 24px;
    flex: 1;

    form {
        width: 100%;

        & > button {
            margin-top: 32px;
            width: 100%;
        }
    }
}

.lineItems {
    padding: 24px;
    background-color: #f5f5f5;
    width: 100%;
    border-radius: 30px;
}

.divider {
    margin: 16px 0;
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
}
