.main {
    display: flex;
    width: 100vw;
    min-height: 100vh;
    padding: 80px;
    padding-top: 120px;
    box-sizing: border-box;

    & * {
        box-sizing: border-box;
    }
}

.container {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    gap: 24px;
    width: 100%;
}

.header {
    background-color: #f7f7f7;
    padding: 32px;
    border-radius: 20px;
    margin-bottom: 40px;
}

.details {
    display: grid;
    gap: 80px;
    width: 500px;
    margin: 0 auto;

    h1 {
        margin-bottom: 24px;
        font-weight: 600;
    }
}

.invoices {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.invoice {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e5e5e5;

    button {
        padding: 16px;
        background-color: #2b2b2b;
        color: white;
        border: none;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500;

        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }
}

.paid {
    background-color: #2eb67d;
    padding: 4px 8px;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 500;
}

.unpaid {
    background-color: #e5e5e5;
    padding: 4px 8px;
    border-radius: 8px;
    color: #2b2b2b;
    font-weight: 500;
}
