.myGarments {
    padding-top: 84px;
}

.garmentsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(63, 63, 63, 0.25);
}

.garmentListHeader {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid rgba(63, 63, 63, 0.25);
}

.emptyState {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    margin: 0 auto;
    text-align: center;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        width: min(100%, 480px);
    }
}

.garmentsTable {
    min-height: 500px;
    position: relative;
}

.table {
    display: inline-table;
    border-collapse: collapse;
    width: 100%;
}

.header {
    text-align: left;
    color: rgba(63, 63, 63, 0.75);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 24px 0;
}

.cell {
    text-align: left;
    color: rgba(63, 63, 63, 0.75);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 24px 0;
    height: 100px;
}

.row {
    &:not(:last-child) {
        border-bottom: 1px solid rgba(63, 63, 63, 0.25);
    }
}

.tableHeader {
    border-bottom: 1px solid rgba(63, 63, 63, 0.25);
    padding: 0;

    &:nth-of-type(1) {
        width: 60%;
    }

    &:nth-of-type(2) {
        width: 20%;
    }

    &:nth-of-type(3) {
        width: 20%;
    }
}

.name {
    color: #2b2b2b;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.statusBadge {
    display: inline-flex;
    padding: 8px 16px;
    align-items: center;
    border-radius: 50px;

    p {
        color: inherit;
        font-weight: 600;
        text-transform: capitalize;
        font-size: 16px;
    }
}

.incomplete {
    background-color: #fce8e9;
    color: #e41c24;
}

.completed {
    background-color: #ebfaf3;
    color: #2eb67d;
}

.processing {
    background-color: #faeaf7;
    color: #9f2589;
}

.searchInput {
    display: flex;
    width: 360px;
    padding: 12px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: rgba(43, 43, 43, 0.05);
    border: none;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.loadingContianer {
    position: absolute;
    width: 100%;
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
