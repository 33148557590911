@import '../../../../Style/theme.scss';
@import '../../../../Style/typographie.scss';

.ArticleCard {
    width: 386px;
    min-height: 420px;
    cursor: pointer;

    @media (max-width: 400px) {
        width: 100%;
    }

    @media (max-width: 1000px) {
        margin-left: auto;
        margin-right: auto;
    }

    .wrapper-img {
        height: 271px;
        position: relative;
        border-radius: 20px;

        .spiner-wrapper {
            position: absolute;
            top: 50%;
            left: 45%;
            .ant-spin-dot-item {
                background-color: #2eb67d !important;
            }
        }

        img {
            height: 271px;
            width: 100%;
            border-radius: 20px;

            object-fit: cover !important;
        }

        @media (max-width: 768px) {
            width: 100%;
            height: 271px;

            img {
                width: 100%;
                height: 271px;
            }
        }
    }

    .card-content {
        padding: 16px 0;
    }

    .category {
        color: #2eb67d;
        border: 2px solid #2eb67d;
        width: max-content;
        padding: 0 19px;
        border-radius: 5px;
        text-transform: uppercase;
    }

    .fake-category {
        height: 28px;

        @media (max-width: 768px) {
            height: 0;
        }
    }

    .title {
        color: $element-greys-primary;
        margin-top: 16px;
        margin-bottom: 8px;
        font-weight: 500 !important;
        white-space: pre-wrap;
        word-break: keep-all;

        @media (max-width: 768px) {
            min-height: auto !important;
        }
    }

    .author,
    .date {
        color: #3f3f3f;
        margin-top: 8px;
    }

    .date {
        font-weight: 300 !important;
        margin-top: 12px;
    }

    &:hover {
        opacity: 0.8;
    }
}
