@import '../../../../Style/theme.scss';
@import '../../../../Style/typographie.scss';

.BlogPageBanner {
    * {
        box-sizing: border-box;
    }

    position: relative;
    display: flex;
    flex-direction: column;
    height: 48vw;
    overflow: hidden;
    min-height: 750px;
    max-height: calc(100svh - 80px);
    padding: 0 0 !important;

    @media (max-width: 768px) {
        width: 100vw;
        min-height: auto;
        max-height: 650px;
        height: calc(100svh - 80px);
    }

    .banner-title {
        display: flex;
        padding: 24px 0;
        background-color: #ffffff;
        justify-content: center;
        align-items: center;

        div {
            flex: 1;
            max-width: 1253px;
            margin: 0 48px;

            @media (max-width: 1000px) {
                margin: 0 16px;
            }
        }

        .title-green {
            color: #2eb67d;
        }
    }

    .wrapper-video {
        height: 100%;
        overflow: hidden;

        @media (max-width: 768px) {
            width: auto;
            position: relative;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }

        .img {
            overflow: hidden;
            object-fit: cover;
            height: 100%;
            width: 100%;

            @media (max-width: 768px) {
                height: 100% !important;
                width: 100% !important;
                object-fit: cover;
                object-position: center;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 73px;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) -10.77%,
                    rgba(0, 0, 0, 0.11) 36.35%,
                    rgba(0, 0, 0, 0.2) 53.04%,
                    rgba(0, 0, 0, 0.43) 67.72%,
                    rgba(0, 0, 0, 0.65) 85.79%,
                    #000 107.06%
                ),
                linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
            overflow-y: hidden;

            @media (max-width: 768px) {
                top: 0px !important;
            }
        }
    }

    .wrapper-content-info {
        position: absolute;
        inset: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        margin: 0 auto;
        padding-bottom: 120px;

        .content-info-container {
            display: flex;
            width: 1252px;

            @media (max-width: 1348px) {
                width: calc(100% - 96px);
            }

            @media (max-width: 1000px) {
                width: calc(100% - 32px);
            }
        }

        .content-info {
            display: flex;
            flex: 1;
            flex-direction: column;
            gap: 32px;
            max-height: fit-content;
            box-sizing: border-box;

            @media (max-width: 768px) {
                gap: 24px;
            }

            h2 {
                flex: 1;
            }

            .cateogry {
                border: 1px solid $white;
                padding: 0 19px;
                width: max-content;
                border-radius: 5px;
                text-transform: uppercase;
            }

            .wrapper-time {
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }

        @media (max-width: 1000px) {
            padding-bottom: 60px;
        }
    }
}
