.heading1 {
    color: #2b2b2b;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-transform: capitalize;

    @media screen and (max-width: 768px) {
        font-size: 32px;
        line-height: 120%;
    }
}

.subheading1 {
    color: #2b2b2b;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;

    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.body1 {
    color: #2b2b2b;
    /* Spree/Montserrat/Regular 18 */
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
}
