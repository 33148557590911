.dialogContent {
    width: min(100vw, 1320px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 68px 48px;
    gap: 68px;

    @media (max-width: 1319px) {
        width: 90vw;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 32px;
        background: #fff;

        h2 {
            margin-bottom: 18px;
        }

        button {
            align-self: flex-start;
        }
    }

    .right {
        display: flex;
        justify-content: center;
        align-items: center;

        .videoContainer {
            height: 500px;
            width: 100%;
            background-color: #d9d9d9;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.dialogButtons {
    display: flex;
    gap: 64px;
    justify-content: flex-start;
    margin-top: 32px;
}
