.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0px 25px 40px -10px rgba(28, 39, 49, 0.08);
    backdrop-filter: blur(17.5px);
}

.container {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    height: 80px;
    padding: 0 60px;

    @media (max-width: 1320px) {
        padding: 0 24px;
    }

    @media (max-width: 768px) {
        padding: 0 16px;
    }
}

.logo {
    display: flex;
    align-items: center;
    width: 215px;

    @media (max-width: 850px) {
        width: 150px;
    }

    @media (max-width: 768px) {
        width: 120px;
    }
}
.right {
    display: flex;
    align-items: center;
    gap: 40px;
}

.nav {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 850px) {
        display: none;
    }

    ul {
        display: flex;
        gap: 40px;

        li {
            list-style: none;
            a {
                color: #000;
                text-decoration: none;
            }
        }
    }
}

.dropdownTrigger {
    display: flex;
    width: 215px;
    height: 48px;
    padding: 10px 18px;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    border-radius: 10px;
    background: #2b2b2b;
    box-shadow: 0px 6px 12px 0px rgba(28, 39, 49, 0.15);
    border: none;

    @media (max-width: 768px) {
        width: 200px;
    }

    &[data-state='open'] {
        border-radius: 10px 10px 0px 0px;
    }

    &[data-state='open'] > .caret {
        transform: rotate(180deg);
    }

    div {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;

            &:first-of-type {
                display: flex;
                align-items: center;
            }
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.caret {
    transition: transform 0.3s ease;
}

.dropdownContent {
    width: 215px;
    border-radius: 0px 0px 10px 10px;
    background: #2b2b2b;
    padding: 10px 10px;

    @media (max-width: 768px) {
        width: 200px;
    }
}

.dropdownItem {
    display: flex;
    align-self: flex-start;
    padding: 10px 10px;
    width: 100%;
    border: none;
    background-color: transparent;
    border-radius: 8px;
    color: #fff;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;

    &:hover {
        border: none;
        outline: none;
        background-color: #3b3b3b;
        cursor: pointer;
    }
}

.logout {
    color: red;
}
