.FaqItem {
    padding: 32px;
    background-color: #f9f9f9;
    border-radius: 30px;
    cursor: pointer;

    * {
        box-sizing: border-box;
    }

    .top {
        display: flex;
        gap: 8px;

        .icon {
            display: flex;
            flex-shrink: 0;
            // padding-bottom: 10px;
            font-size: 24px;
            justify-self: flex-start;
        }
    }

    .large {
        justify-content: space-between;
    }

    .question {
        color: #3f3f3f;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;

        @media (max-width: 1000px) {
            font-size: 16px;
        }
    }

    .bottom {
        overflow: hidden;
        // transition: all ease 0.2s;
    }

    .answer {
        gap: 0.3ch;
        margin-top: 19px;

        .text-underline {
            text-decoration: underline;
        }

        @media (max-width: 768px) {
            margin-top: 16px;
        }

        a {
            color: #3f3f3f !important;
            text-decoration: underline;
        }
    }

    .icon {
        align-self: flex-start;
        transform-origin: center;
    }

    .fake {
        position: absolute;
        z-index: -1;

        * {
            color: transparent !important;

            a {
                color: transparent !important;
            }
        }
    }
}
