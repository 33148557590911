@import '../../Style/theme.scss';

.ContactUs {
    background: linear-gradient($surface-grey-opposite 25%, #393939 66%, white 66%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 58px 18px 100px;
    position: relative;
    overflow: hidden;
    height: 723px;

    margin-top: 80px;

    .ellipses {
        display: block;
    }

    textarea {
        min-height: 0px !important;
    }

    .form-container {
        width: 774px;
    }

    .WrapperSectionTitle {
        text-align: center;
        margin-bottom: 24px;
        margin-top: 120px;

        @media screen and (max-width: 450px) {
            margin-top: 0;
            margin-bottom: 24px;
            margin-right: auto;
        }
    }

    .subTitle {
        text-align: center;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        z-index: 1;

        p {
            white-space: pre-wrap;
            word-break: keep-all;
        }

        a {
            color: $white !important;
            white-space: pre-wrap;
            word-break: keep-all;
        }

        .text-underline {
            cursor: pointer;
            text-decoration: underline;
        }

        // .text-body {
        //     display: flex;
        //     flex-direction: row;
        //     gap: 0.5ch;
        //     margin-left: auto;
        //     margin-right: auto;
        // }

        // @media screen and (max-width: 450px) {
        //     .text-body {
        //         flex-direction: column;
        //         gap: 0.5ch;
        //         margin-left: 0;
        //     }
        //     margin-right: auto;
        //     text-align: left;
        // }
    }

    .Form {
        box-shadow: 0px 25px 60px -10px rgba(28, 39, 49, 0.12);
        position: relative;
        z-index: 1;

        .form-title {
            margin-top: 0px !important;
        }

        .side-by-side {
            width: 100%;
            display: flex;
            column-gap: 10px;
            text-wrap: nowrap;
            align-items: center;

            .BasicInput {
                width: 100%;
            }
        }

        .FormSubmit {
            text-align: center;
        }
    }

    @media screen and (max-width: 650px) {
        position: relative;
        background: radial-gradient(125.06% 110.94% at 50% 100%, #121212 0%, #333 100%),
            linear-gradient($surface-grey-opposite 25%, $surface-grey-opposite 66%, white 50%);
        padding: 80px 16px;
        height: auto;

        .ellipses {
            display: none;
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            bottom: 0;
            width: 100%;
            background: white;
            z-index: 0;
        }

        h1 {
            margin-top: 0;
            font-size: 28px;
            font-weight: 600;
            line-height: 125%;
        }

        .form-container {
            position: relative;
            z-index: 1;
            width: 100%;
            margin: 0 1020px;

            .Form {
                width: 100%;
                .form-content {
                    padding: 40px 20px 40px 20px;
                }
                .side-by-side {
                    flex-direction: column;
                    row-gap: 20px;
                }
            }
        }
    }
}
