.sectionWrapper {
    display: flex;
    justify-content: center;
    padding: 100px 0;
    margin: 0 auto;
    background-color: #ffffff;

    @media (max-width: 1000px) {
        padding: 60px 0;
    }
}

.sectionContainer {
    position: relative;
    max-width: 1200px;
    display: flex;
    align-items: center;
    gap: 80px;
    padding: 0 32px;

    @media (max-width: 750px) {
        flex-direction: column;
        gap: 32px;
        padding: 0 24px;
    }
}

.details {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    gap: 24px;

    @media (max-width: 750px) {
        flex: 0;
    }

    h1 {
        font-size: 3rem;
        font-weight: 500;
        color: #2b2b2b;
    }

    a {
        align-self: flex-start;

        @media (max-width: 750px) {
            width: 100%;
        }
    }
}

.image {
    display: flex;
    flex: 1;
    border-radius: 30px;
    height: auto;
    width: 100%;
    overflow: hidden;
    min-height: min-content;

    & > img {
        width: 100%;
        object-fit: cover;
    }

    @media (max-width: 750px) {
        flex: auto;
    }
}
