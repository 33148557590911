.main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: calc(100vh - 80px);
}

.container {
    display: flex;
    flex-direction: column;

    padding: 120px 0;
    align-items: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-right: 64px;
    padding-left: 24px;
    width: min(100%, 604px + 24px);
}

.left {
    align-items: flex-end;
}

.form {
    display: grid;
    gap: 24px;

    div {
        display: grid;
        grid-template-columns: auto auto;
        gap: 20px;

        label {
            color: #777;
            font-size: 16px;
            font-style: normal;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-start;
            border: 2px solid #2b2b2b;
            background-color: #ffffff;
            border-radius: 4px;
            width: 32px;
            height: 32px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    button {
        justify-self: flex-start;
    }
}

.description {
    display: flex;
    flex-direction: column;
    gap: 28px;

    h2 {
        color: #2b2b2b;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }
}

.button {
    border: none;
    background-color: transparent;
    color: #2eb67d;

    display: flex;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

    &:hover {
        cursor: pointer;
    }

    svg {
        margin-left: 8px;
    }
}

.right {
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.07));
    align-items: flex-start;
    background-color: #fff;
    padding-left: 64px;
    padding-right: 24px;

    &Wrapper {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 48px 64px;
        border-radius: 30px;
        background: rgba(46, 182, 125, 0.05);
        width: min(100%, 688px);

        .icon {
            margin-bottom: 24px;
        }

        h4 {
            color: #2b2b2b;

            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
        }
    }
}

.price {
    color: #2b2b2b;

    font-family: Poppins;
    font-size: 96px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.featureList {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding-top: 24px;

    li {
        display: flex;
        padding: 8px 16px;
        align-items: center;
        align-self: stretch;
        border-radius: 20px;
        background: #fff;
        gap: 20px;

        p {
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        svg {
            align-self: flex-start;
            flex-shrink: 0;
        }
    }
}
