.main {
    width: 100vw;
    min-height: calc(100svh - 80px);
    padding: 120px 0;
}

.container {
    width: min(100%, 1362px + 48px);
    padding: 0 24px;
    margin: 0 auto;
}

.cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}

.card {
    display: flex;
    padding: 44px 44px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 30px;
    border: 1px solid #707070;
    background: #fff;
    gap: 24px;

    button {
        align-self: flex-end;
    }
}

.provideAssets {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.requirements {
    h4 {
        padding-bottom: 24px;
    }
}

.requirementsList {
    display: flex;
    flex-direction: column;
    gap: 10px;

    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        p {
            font-weight: 400;
            font-family: 'Poppins', sans-serif;
        }
    }
}
