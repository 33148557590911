.sectionWrapper {
    display: flex;
    justify-content: center;
    padding: 100px 0;
    margin: 0 auto;
    background-color: #ffffff;

    @media (max-width: 1000px) {
        padding: 60px 0;
    }
}

.sectionContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 40px;
    max-width: 1200px;
    padding: 0 32px;

    @media (max-width: 750px) {
        flex-direction: column;
        gap: 32px;
        padding: 0 24px;
    }

    a {
        align-self: center;

        @media (max-width: 750px) {
            align-self: auto;
        }
    }
}

.divider {
    width: 100%;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.5);
}

.jobList {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 100%;

    @media (max-width: 1000px) {
        gap: 16px;
    }

    li {
        list-style: none;
        width: 100%;
    }
}
