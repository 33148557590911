.support {
    display: flex;
    width: 100vw;
    padding: 0 24px;
    box-sizing: border-box;
    justify-content: center;

    & * {
        box-sizing: border-box;
    }
}

.container {
    width: min(100%, 1362px + 48px);
    padding: 120px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 146px;
}

.formContainer {
    border-radius: 20px;
    border: 1px solid #777;
    backdrop-filter: blur(35px);
    display: flex;
    padding: 72px 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;

    form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;

        button {
            &[type='submit'] {
                margin-top: 20px;
            }
        }
    }
}

.aside {
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
}

.asideContent {
    display: flex;
    flex-direction: column;
    margin-top: -80px;
    gap: 40px;
    justify-content: center;
}
