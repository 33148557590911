.PolicyPrivacyTableOfContents {
    .links,
    .links-items {
        list-style: none;
    }

    .links-item button {
        text-decoration: underline;
    }
}
