.carouselContainer {
    position: relative;
    height: calc(100% - 96px);
    padding: 34px 120px 48px 0;
    width: calc(520px + 48px);

    @media (max-width: 1319px) {
        width: 35vw;
    }

    @media (max-width: 1000px) {
        padding: 0;
        padding-bottom: 60px;
        width: 70vw;
    }
}

.carouselWrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.slide {
    position: relative;
    box-sizing: border-box;
    padding: 24px;
    overflow-y: visible;
}

.slideContent {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    padding: 32px;
    height: 423px;
    width: 520px;
    box-sizing: border-box;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.1);
    background-size: cover;
    background-position: center;

    @media (max-width: 1319px) {
        width: 32vw;
    }

    @media (max-width: 1000px) {
        width: 65vw;
    }
}

.bgMetadata {
    background-image: url('../../../Assets/Img/PartnerPortal/metadata-modal.jpg');
}

.bgGarment {
    background-image: url('../../../Assets/Img/PartnerPortal/garment-image.jpg');
}

.bgModel {
    background-image: url('../../../Assets/Img/PartnerPortal/model-images.jpg');
}

.bgTechPack {
    background-image: url('../../../Assets/Img/PartnerPortal/tech-pack.jpg');
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 28.69%,
        rgba(255, 255, 255, 0.6) 53.86%,
        #fff 89.01%
    );
}

.slideFooter {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    z-index: 10;

    @media (max-width: 1319px) {
        flex-direction: column;
    }

    h5 {
        font-family: 'Poppins', sans-serif;
    }

    svg {
        flex-shrink: 0;
    }
}

.blank {
    background-color: red;
    width: 100%;
}

.dotsList {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    list-style: none;

    li {
        height: auto;
        width: auto;
        margin: 0;
    }

    li button::before {
        // font-size: 8px;
        color: transparent !important;
    }
}

.carouselControlsContainer {
    display: flex;
    width: 100vw;
    justify-content: center;
    // justify-content: space-between;
    align-items: center;
    max-width: 990px;
    margin: 0 28px;
    box-sizing: border-box;

    @media (max-width: 1000px) {
        width: 65vw;
        max-width: none;
    }

    @media (max-width: 750px) {
        width: 90vw;
    }
}

.nextButton {
    position: absolute;
    top: calc(50% - 24px);
    right: -24px;
    transform: translateX(50%);
}

.prevButton {
    position: absolute;
    top: calc(50% - 24px);
    left: -24px;
    transform: translateX(-50%);
}
