.main {
    * {
        box-sizing: border-box;
        position: relative;
    }
}

.backgroundImage {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0;
    animation: scaleUp 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}

@keyframes scaleUp {
    0% {
        transform: scale(0.2);
        opacity: 0;
        filter: blur(10px);
    }

    100% {
        transform: scale(1);
        opacity: 1;
        filter: blur(0);
    }
}

.icon {
    position: absolute;
    z-index: -1;
}

.container {
    padding: 0px 16px;
    margin: 0 auto;
    max-width: calc(768px + 32px);

    display: grid;
    justify-content: center;
    align-items: center;

    min-height: calc(100svh - 80px);
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
        color: #1b1d1e;
        font-family: Poppins;
        font-size: 100px;
        font-style: normal;
        font-weight: 500;
        line-height: 113%;

        padding-bottom: 15px;
    }

    p {
        padding-bottom: 62px;
        max-width: 509px;
    }

    .button {
        width: 100%;
        width: min(100%, 414px);
    }
}
