.BlogPage {
    margin-top: 80px;

    @media screen and (max-width: 1000px) {
        padding-bottom: 0;
    }

    .FuturOfFashionSection {
        padding-top: 0px !important;
    }
}
