@import '../../../../Style/theme.scss';

$BREAKPOINT_TABLET: 750px;

.careersSection {
    display: flex;
    flex-direction: column;
    gap: 46px;
    padding: 100px 32px;
    justify-items: center;
    background-color: #ffffff;

    @media (max-width: $BREAKPOINT_TABLET) {
        font-size: 14px !important;
        padding: 60px 24px;
    }

    @media (max-width: 550px) {
        gap: 32px;
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerContent {
    display: flex;
    flex-basis: 1200px;
    flex-direction: column;
    gap: 60px;

    @media (max-width: 550px) {
        gap: 32px;
    }
}

.carouselContent {
    max-width: 1242px;
    flex-basis: 1242px;

    @media (max-width: 1260px) {
        max-width: auto;
        width: 100vw;
    }
}

.careersHeader {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    gap: 16px;

    @media (max-width: 750px) {
        gap: 12px;
    }

    h1 {
        font-weight: 500 !important;
    }

    & > p {
        font-size: 18px;
        font-weight: 700;
    }
}

.searchContainer {
    position: relative;
    display: flex;
    align-items: center;

    input {
        width: 100%;
        padding: 12px 1rem;
        border: 1px solid #777777;
        border-radius: 8px;
        font-size: 1rem;

        &:focus {
            border: 1px solid #2b2b2b;
            outline: none;
        }
    }

    svg {
        position: absolute;
        right: 20px;
    }
}

.careerControls {
    display: flex;
    gap: 12px;
    align-items: center;

    @media (max-width: $BREAKPOINT_TABLET) {
        align-items: normal;
        flex-direction: column;
        gap: 16px;
    }
}

.buttonPrimary {
    padding: 8px 1rem;
    border: none;
    border-radius: 8px;
    background-color: #2b2b2b;
    color: white;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    min-width: fit-content;

    &:hover {
        cursor: pointer;
        opacity: 0.85;
    }

    &:disabled {
        background-color: #d3d3d3;
        color: #777777;
        cursor: not-allowed;
    }
}

.buttonText {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 1rem 8px 8px;
    border: none;
    border-radius: 8px;
    background-color: transparent;
    color: #0b1926;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    min-width: fit-content;
    transition: all 0.3s ease;
    align-self: flex-start;

    @media (max-width: 750px) {
        font-size: 16px;
    }

    &:hover {
        background-color: rgba(238, 238, 238, 0.8);
        cursor: pointer;
    }

    &:focus {
        background-color: #eeeeee;
        cursor: pointer;
    }
}

.selectWrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 244px;

    @media (max-width: $BREAKPOINT_TABLET) {
        max-width: 100%;
    }
}

.loading {
    display: flex;
    justify-content: center;
    align-self: center;
}

.spinner {
    height: 48px;
    width: 48px;
    border-radius: 9999px;
    border-top: 4px solid #2b2b2b;
    border-right: 4px solid #2b2b2b;
    border-bottom: 4px solid #f9f9f9;
    border-left: 4px solid #f9f9f9;
    animation: rotate 1s ease-in-out infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
