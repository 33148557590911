.FaqSection {
    background-color: #f9f9f9;

    .content {
        padding: 120px 16px;

        margin-left: auto;
        margin-right: auto;

        display: flex;
        gap: 300px;
        justify-content: center;

        @media screen and (max-width: 1426px) {
            gap: 80px;
        }

        @media screen and (max-width: 800px) {
            flex-direction: column;
            padding: 80px 16px;
            gap: 40px;
        }
    }

    .left {
        .WrapperSectionTitle {
            text-align: left;
            max-width: 500px;

            @media screen and (max-width: 800px) {
                max-width: 100%;
                width: 100%;
                text-align: center;
            }
        }
    }

    .right {
        width: 550px;

        .wrapper-faq {
            gap: 24px;
            display: flex;
            flex-direction: column;

            .FaqItem {
                background: transparent;
                padding: 0;

                .top {
                    grid-template-columns: 1fr 6fr;
                    .question {
                        font-size: 18px !important;
                        color: #3f3f3f;
                        padding-left: 24px;

                        @media screen and (max-width: 779px) {
                            padding-left: 16px;
                        }
                    }
                }

                .bottom {
                    .answer {
                        padding-left: 58px;

                        @media screen and (max-width: 779px) {
                            padding-left: 50px !important;
                        }
                    }
                }

                .fake {
                    padding-left: 80px;
                    max-width: 550px;
                    @media screen and (max-width: 779px) {
                        padding-left: 14% !important;
                    }
                }
            }
        }

        @media screen and (max-width: 800px) {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (max-width: 510px) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
