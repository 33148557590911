.container {
    max-width: calc(1280px + 24px + 24px);
    margin: 0 auto;
    padding: 0 24px;

    @media (max-width: 768px) {
        max-width: auto;
        width: 100vw;
        padding: 0px;
    }
}

.grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    background: linear-gradient(0deg, #343434 0%, #121212 100%);
    padding: 120px;
    border-radius: 30px;

    @media (max-width: 1100px) {
        padding: 64px;
    }

    @media (max-width: 768px) {
        padding: 60px 24px;
        border-radius: 0px;
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            padding-bottom: 24px;
        }

        p {
            padding-bottom: 40px;
        }
    }

    .ellipse {
        position: absolute;
        z-index: 0;
        height: 80%;
    }

    .right {
        top: 0;
        right: 0;
    }
    .left {
        bottom: 0;
        left: 0;
        transform: rotate(180deg);
    }
}
