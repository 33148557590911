.section {
    background-color: #f9f9f9;
    width: 100vw;
    box-sizing: border-box;
    padding: 80px 0;

    & * {
        box-sizing: border-box;
    }

    .container {
        padding: 0 16px;
    }

    .headerWrapper {
        max-width: 1280px;
        margin: 0 auto;
    }
}

.header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 8px;

    h2 {
        font-size: 48px;
        line-height: 120%;
        font-weight: 500;
        font-style: normal;
        color: #2b2b2b;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;

        & > span {
            font-family: 'Poppins', sans-serif;
        }

        @media (max-width: 950px) {
            font-size: 32px;
        }
    }

    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        color: #2b2b2b;

        @media (max-width: 500px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.dotsList {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    list-style: none;

    li {
        height: auto;
        width: auto;
        margin: 0;
    }

    li button::before {
        // font-size: 8px;
        color: transparent !important;
    }
}

.carouselContainer {
    position: relative;
    max-width: 1320px;
    margin: 0 auto;

    @media (max-width: 1320px) {
        width: 100vw;
        max-width: auto;
    }

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        width: 200px;
        height: 480px;
        background: linear-gradient(90deg, rgba(249, 249, 249, 0) -5.08%, #f9f9f9 50.12%);
        z-index: 2;

        @media (max-width: 750px) {
            display: none;
        }
    }
}

.controlsContainer {
    display: flex;
    justify-content: center;

    @media (max-width: 1320px) {
        width: 100vw;
        padding: 0 16px;
    }
}

.carouselControls {
    display: flex;
    width: 100vw;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin-top: 16px;

    @media (max-width: 1320px) {
        padding: 0;
    }

    @media (max-width: 750px) {
    }
}
