.section {
    height: 800px;
    background: radial-gradient(112.73% 100% at 50% 100%, #121212 0%, #333 100%),
        radial-gradient(95.48% 190.61% at 52.76% 100%, #121212 0%, #333 100%);

    * {
        box-sizing: border-box;
    }

    @media (max-width: 1000px) {
        height: auto;
    }
}

.container {
    width: min(100%, 1280px);
    height: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 456px;
    gap: 90px;
    padding: 0 24px;

    @media (max-width: 1000px) {
        grid-template-columns: 100vw;
        gap: 48px;
        padding: 0;
    }
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;

    @media (max-width: 1000px) {
        padding: 80px 24px 0px 24px;
    }

    @media (max-width: 768px) {
        padding: 80px 16px 0px 16px;
    }

    h2,
    p {
        color: #ffffff;

        span {
            color: #2eb67d;
            font-weight: 600;
        }
    }
}

.right {
    display: grid;
    grid-template-columns: 216px 216px;
    gap: 24px;
    overflow: hidden;

    @media (max-width: 1000px) {
        grid-template-columns: 100vw;
        grid-template-rows: 189px 189px;
    }
}

.col {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 1000px) {
        display: none;
    }
}

.colMarquee {
    display: flex;
    gap: 24px;
    flex-direction: column;
    width: 100%;
    transform: translateY(0);
    will-change: transform;
    animation: scrollVertical 35s linear infinite;
    -webkit-animation: scrollVertical 35s linear infinite;

    img {
        width: 100%;
        object-fit: cover;
    }
}

.row {
    display: flex;
    gap: 24px;
}

.rowMarquee {
    display: flex;
    gap: 24px;
    transform: translateX(0);
    will-change: transform;
    animation: scrollHorizontal 35s linear infinite;
    -webkit-animation: scrollHorizontal 35s linear infinite;

    img {
        width: 164px;
        height: 100%;
        object-fit: cover;
    }

    @media (min-width: 1000px) {
        display: none;
    }
}

@-webkit-keyframes scrollHorizontal {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% - 24px));
    }
}

@keyframes scrollHorizontal {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% - 24px));
    }
}

@-webkit-keyframes scrollVertical {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% - 24px));
    }
}

@keyframes scrollVertical {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(calc(-100% - 24px));
    }
}
