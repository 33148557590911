.HomePage {
    // *,
    // *::before,
    // *::after {
    //     box-sizing: border-box;
    // }

    scroll-behavior: smooth;

    .separator-how-it-works-explination {
        margin-top: 147px;
    }

    @media screen and (max-width: 800px) {
        padding: 0;
    }

    .FuturOfFashionSection {
        padding: 80px 16px !important;
    }
}
