@import '../../../../Style/theme.scss';

@property --rotate {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
}

.section {
    padding: 100px 0;

    @media (max-width: 768px) {
        padding: 50px 0;
    }
}

.container {
    max-width: calc(1280px + 24px + 24px);
    margin: 0 auto;
    padding: 0 24px;
}

.fancy {
    --rotate: 0deg;
    position: relative;
    background-color: $surface-grey-opposite;
    padding: 3px;
    border-radius: 100px;

    &::before {
        content: '';
        position: absolute;
        // background: conic-gradient(
        //     from var(--rotate),
        //     transparent 0%,
        //     $element-brand-rest-primary 10%,
        //     transparent 10%
        // );
        // background: conic-gradient(
        //     from var(--rotate),
        //     violet,
        //     indigo,
        //     blue,
        //     green,
        //     yellow,
        //     orange,
        //     red,
        //     violet
        // );
        background: conic-gradient(from var(--rotate), #399da9, #34a797, #2fb481, #2eb67d);
        filter: brightness(1.2);
        inset: 0;
        border-radius: 100px;
        z-index: 1;
        animation: rotate 5s linear infinite;
    }

    a {
        position: relative;
        z-index: 2;
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 545px;
    gap: 80px;
    align-items: center;

    @media (max-width: 768px) {
        gap: 24px;
        grid-template-columns: 1fr;
        grid-template-rows: min-content min-content;
    }
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;

    @media (max-width: 768px) {
        order: 2;
        gap: 24px;
    }
}

.buttons {
    display: flex;
    gap: 24px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
    }
}

.right {
    position: relative;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

@keyframes rotate {
    to {
        --rotate: 360deg;
    }
}
