.sectionWrapper {
    display: flex;
    justify-content: center;
    padding: 100px 32px;
    margin: 0 auto;
    background-color: #fafafa;
    width: 100vw;
    box-sizing: border-box;

    @media (max-width: 750px) {
        padding: 60px 24px;
    }

    * {
        box-sizing: border-box;
    }
}

.sectionContent {
    max-width: 768px;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 80px;

    @media (max-width: 750px) {
        width: 100%;
        max-width: fit-content;
        flex-direction: column;
        gap: 60px;
    }
}

.sectionHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    & > p {
        text-align: center;
    }
}

