.pendingItems {
    display: grid;
    grid-template-columns: 1fr;
    min-height: calc(100svh - 80px);
    padding: 120px 0;
    background-image: url('../../Assets/Img/PartnerPortal/phone-background.jpg');
    background-size: cover;
    background-position: center;
}

.container {
    width: min(100%, 1362px + 48px);
    margin: 0 auto;
    padding: 0 24px;
}

.content {
    display: grid;
    gap: 40px;
    width: min(100%, 842px);
    padding: 64px 48px;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 0px 30px 0px rgba(43, 43, 43, 0.28);
}

.header {
    display: grid;
    gap: 32px;

    & > button {
        &:first-of-type {
            margin-bottom: 32px;
        }
    }
}

.textButton {
    color: #2b2b2b;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 27px */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;

    padding: 0;
    justify-self: flex-start;
    background-color: transparent;
    border: none;

    &:hover {
        cursor: pointer;
    }
}

.cards {
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr;
}

.actionCard {
    display: flex;
    padding: 32px 22px;
    height: 100%;
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1px solid #777;
    background: #fff;
    text-align: left;

    .status {
        padding: 13px;
        border-radius: 9px;
        background: #fff0cf;
        width: 100%;
        color: #fc6101;
        text-transform: uppercase;
        text-align: center;

        p {
            color: inherit;
            font-weight: 500;
        }
    }

    .completed {
        background: #e5f7f0;
        color: #2eb67d;
    }
}

.wrapperButton {
    padding: 0;
    border: none;
    border-radius: 30px;
    background-color: transparent;

    &:hover {
        cursor: pointer;
    }
}

.dialogContent {
    width: 1320px;
    min-height: 624px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1319px) {
        width: 90vw;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 80px;
        gap: 40px;
        background: #fff;
    }

    .right {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #2eb67d1c;

        img {
            border-radius: 20px;
            height: 360px;
            width: 360px;
        }
    }
}

.pendingApproval {
    display: flex;
    width: 744px;
    padding: 12px 64px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #2b2b2b;
    opacity: 50%;

    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}
