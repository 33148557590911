.sliderButtons {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

.sliderButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    border-radius: 9999px;
    min-width: fit-content;

    &:hover {
        cursor: pointer;
    }
}

.lightModeButton {
    color: #2b2b2b;
    border: 1px solid #2b2b2b;
    background-color: transparent;
    transition: all 0.3s;

    svg {
        fill: #2b2b2b;
    }

    &:hover {
        background-color: #2b2b2b;
        color: #ffffff;

        svg {
            fill: #ffffff;
        }
    }
}

.darkModeButton {
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: transparent;
    transition: all 0.3s;

    svg {
        fill: #ffffff;
    }

    &:hover {
        background-color: #ffffff;
        color: #2b2b2b;

        svg {
            fill: #2b2b2b;
        }
    }
}
