.fileInput {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.label {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.helperText {
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
}

.error {
    color: #d7382d;
}

.required {
    margin-left: 4px;
    color: #d7382d;
}

.button {
    display: flex;
    padding: 12px 8px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid rgba(43, 43, 43, 0.5);
    background-color: #ffffff;

    &:hover {
        cursor: pointer;
    }

    .text {
        color: #2b2b2b;

        /* Body/Medium */
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
}
