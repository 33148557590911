@import './../../../../Style/breakpoint.scss';

.AboutUsTeamsSectionPage {
    padding: 80px 0;

    .title {
        text-align: center;
        padding-bottom: 64px;
    }

    .note {
        margin-top: 60px;
        text-align: center;
        color: #555555;
        line-height: 24px;

        @media (max-width: 1000px) {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px;
        }

        @media screen and (max-width: 630px) {
            margin-left: 16px;
            text-align: left;
            font-size: 14px !important;
        }
    }

    .teams {
        display: flex;
        flex-wrap: wrap;
        gap: 56px;
        row-gap: 48px;
        max-width: 775px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 16px;
        justify-content: center;

        @media (max-width: 530px) {
            justify-content: left;
            margin-top: -20px;
            .MemberOfTeam {
                width: calc(50% - 32px);
                height: 208px;
                padding-top: 20px;

                .card-container {
                    .side {
                        img {
                            width: 100px !important;
                            height: 100px !important;
                        }
                    }
                }

                .info {
                    margin-top: 100px;
                }
            }
        }

        @media (max-width: map-get($breakpoints, 'tablet')) and (min-width: map-get($breakpoints, 'l')) {
            justify-content: center;
        }
    }
}
