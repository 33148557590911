.accordionRoot {
    border-radius: 6px;
    width: 100%;
}

.accordionItem {
    overflow: hidden;
    border-radius: 20px;
    transition: background-color 300ms ease-in-out;
    padding: 32px;

    &[data-state='open'] {
        background-color: rgba(46, 182, 125, 0.1);
    }
}

.accordionHeader {
    display: flex;
    overflow: hidden;
}

.accordionTrigger {
    font-family: inherit;
    background-color: transparent;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    font-size: 15px;
    line-height: 1;
    border: none;
    text-align: left;
    padding: 0;

    &:hover {
        cursor: pointer;
    }

    &[data-state='open'] > .accordionChevron {
        transform: rotate(180deg);
    }

    & > h3 {
        font-size: 18px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        line-height: 125%;
        color: #2b2b2b;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }

    svg {
        flex-shrink: 0;
    }
}

.accordionChevron {
    transition: transform 300ms ease-in-out;
}

.accordionContent {
    overflow: hidden;
    font-size: 15px;
    white-space: pre-line;

    &[data-state='open'] {
        animation: slideDown 300ms ease-in-out;
    }

    &[data-state='closed'] {
        animation: slideUp 300ms ease-in-out;
    }

    & > p {
        padding-top: 16px;
    }
}

@keyframes slideDown {
    from {
        height: 0;
    }
    to {
        height: var(--radix-accordion-content-height);
    }
}

@keyframes slideUp {
    from {
        height: var(--radix-accordion-content-height);
    }
    to {
        height: 0;
    }
}

.tabsRoot {
    position: relative;
}

.tabsList {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 18px;
    padding-bottom: 6px;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button {
        display: none;
    }
}

.tab {
    position: relative;
    font-size: 18px;
    line-height: 125%;
    font-weight: 600;
    color: #2b2b2b;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    transition: font-weight 0.3s ease;
    background-color: transparent;
    width: fit-content;
    flex-shrink: 0;

    &:hover {
        cursor: pointer;
    }
}

.bubble {
    position: absolute;
    inset: 0;
    background-color: #eeeeee;
    border-radius: 8px;
}

.line {
    position: absolute;
    bottom: -6px;
    left: 0;
    height: 4px;
    width: 100%;
    background-color: #2eb67d;
    border-radius: 9999px;
}
