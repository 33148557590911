.main {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 80px 0;
    padding: 80px 0;

    @media (max-width: 765px) {
        padding: 40px 0;
    }
}

.container {
    max-width: 721px;
    padding: 0 24px;
    margin: 0 auto;

    @media (max-width: 765px) {
        padding: 0 16px;
    }
}

.divider {
    width: 342px;
    height: 1px;
    background: rgba(0, 0, 0, 0.25);

    @media (max-width: 765px) {
        width: 100%;
    }
}

.logo {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
}

.article {
    display: flex;
    padding: 80px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);

    @media (max-width: 765px) {
        gap: 24px;
        padding: 64px 24px;
    }

    h1 {
        color: #2b2b2b;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        text-align: center;
    }

    p {
        color: #2b2b2b;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @media (max-width: 765px) {
            font-size: 16px;
        }
    }
}
