@import '../../Style/theme.scss';

$BREAKPOINT_TABLET: 750px;

.jobListingsWrapper {
    margin-top: 80px;
    background-color: #ffffff;
}

.heading2 {
    font-size: 48px;
    line-height: 120%;
    font-weight: 500;
    font-style: normal;
    color: #2b2b2b;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;

    & > span {
        font-family: 'Poppins', sans-serif;
    }

    @media (max-width: 950px) {
        font-size: 32px;
    }
}

.body1 {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    color: #2b2b2b;

    @media (max-width: 500px) {
        font-size: 16px;
        line-height: 24px;
    }
}
