.main {
    display: flex;
    width: 100vw;
    min-height: 100vh;
    padding: 0 24px;
    box-sizing: border-box;
    justify-content: center;

    & * {
        box-sizing: border-box;
    }
}

.container {
    width: min(100%, 1362px + 48px);
    padding: 120px 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 48px;
}

.billingCards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 40px;
    padding-bottom: 100px;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #2b2b2b;
    border-radius: 30px;
    padding: 44px;
    gap: 32px;
    color: #fff;

    h4 {
        color: inherit;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    p {
        color: inherit;
    }

    &Header {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h4 {
            color: inherit;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .price {
        font-family: Poppins;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.upcomingDetails {
    border-top: 1px solid #fff;
    width: 100%;
    padding-top: 16px;

    &Footer {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}

.whiteButton {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;

    &:hover {
        background-color: #fff;
        color: #2b2b2b;
    }
}

.paymentMethod {
    border: 1px solid #707070;
    background: #fff;
    color: #2b2b2b;
}

.back {
    display: flex;
    align-items: center;
    justify-self: start;
    gap: 8px;
    padding-bottom: 36px;

    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.table {
    display: grid;

    width: 100%;
}

.billingHeader {
    padding-top: 0px !important;

    div {
        color: #000;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.details {
    display: grid;
    gap: 80px;
    grid-template-columns: 2fr 2fr;
    h1 {
        margin-bottom: 24px;
        font-weight: 600;
    }
}

.invoices {
    display: flex;
    flex-direction: column;
}

.invoice {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
    gap: 8px;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid #e5e5e5;

    div {
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 18px;
    }
}

.tab {
    padding: 8px 16px;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
}

.paid {
    background-color: #e2fff3;
    color: #2eb67d;
}

.unpaid {
    background-color: #ffd0d0;
    color: #b21d1d;
}

.button {
    padding: 12px 16px !important;
    flex-shrink: 0 !important;
    white-space: nowrap;
}

.noInvoices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    height: 100%;
    padding-top: 120px;
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
}

.loadingContianer {
    width: 100%;
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    path {
        stroke: black;
        stroke-width: '1px';
    }
}
