.container {
    max-width: calc(1280px + 24px + 24px);
    margin: 0 auto;
    padding: 0 24px;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 80px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 32px;
    }
}

.aside {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > h2 {
        display: block;
        padding-bottom: 32px;
    }

    @media (max-width: 768px) {
        order: 2;

        & > h2 {
            display: none;
        }
    }
}

.right {
    & > h2 {
        display: none;
    }

    @media (max-width: 768px) {
        & > h2 {
            display: block;
            padding-bottom: 32px;
        }
    }
}

.picture {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50px;

    @media (max-width: 768px) {
        height: auto;
    }
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.accordionRoot {
    border-radius: 6px;
    width: 100%;
}

.accordionItem {
    overflow: hidden;
    border-radius: 20px;
    border: 1px solid transparent;
    transition: all 300ms ease-in-out;
    padding: 24px;

    &[data-state='open'] {
        background-color: #f9f9f9;
        border: 1px solid #707070;
    }
}

.accordionHeader {
    display: flex;
    overflow: hidden;
}

.accordionTrigger {
    font-family: inherit;
    background-color: transparent;
    flex: 1;
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 15px;
    line-height: 1;
    border: none;
    text-align: left;
    padding: 0;

    &:hover {
        cursor: pointer;
    }
}

.accordionContent {
    overflow: hidden;
    font-size: 15px;
    white-space: pre-line;

    // &[data-state='open'] {
    //     animation: slideDown 300ms ease-in-out;
    // }

    // &[data-state='closed'] {
    //     animation: slideUp 300ms ease-in-out;
    // }

    & > p {
        padding-top: 16px;
        padding-left: 36px;
    }
}

// @keyframes slideDown {
//     from {
//         height: 0;
//     }
//     to {
//         height: var(--radix-accordion-content-height);
//     }
// }

// @keyframes slideUp {
//     from {
//         height: var(--radix-accordion-content-height);
//     }
//     to {
//         height: 0;
//     }
// }
