@import '../../../../Style/theme.scss';

.sectionWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 100px 0;
    margin: 0 auto;
    background-color: #ffffff;

    @media (max-width: 1000px) {
        padding: 60px 0;
    }
}

.sectionContainer {
    position: relative;
    max-width: 1200px;
    display: flex;
    align-self: start;
    gap: 80px;
    padding: 0 32px;

    @media (max-width: 750px) {
        flex-direction: column;
        gap: 32px;
        padding: 0 24px;
    }
}

.details {
    position: relative;
    display: flex;
    flex: 1;
    width: 250px;

    @media (max-width: 750px) {
        width: 100%;
    }

    div {
        position: sticky;
        top: 104px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        height: fit-content;

        @media (max-width: 750px) {
            gap: 0px;
            position: initial;
            top: 0;
        }

        & > a {
            align-self: flex-start;
        }

        & > a > button {
            @media (max-width: 750px) {
                display: none;
            }
        }
    }
}

.mobileButton {
    display: none;

    @media (max-width: 750px) {
        display: block;
        width: 100%;
    }
}

.steps {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.stepsList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.step {
    display: flex;
    align-items: baseline;
    gap: 24px;

    & > div {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 48px;
        background-color: #f9f9f9;
        border: none;
        border-radius: 9999px;
        color: $surface-brand-rest-primary;
        font-weight: 700;
        font-size: 18px;
    }

    p {
        font-size: 18px;
        font-weight: 700;
        color: #2b2b2b;
    }
}

.divider {
    height: 32px;
    width: 2px;
    background-color: #2b2b2b;
    margin: 16px 0 16px 24px;
}
