.portalLayout {
    min-height: calc(100svh - 80px);
    width: 100vw;
    padding-top: 80px;

    & * {
        box-sizing: border-box;
    }
}

.mainPortalContent {
    display: block;

    @media (max-width: 850px) {
        display: none;
    }
}
