.emptyMessage {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    text-align: center;

    h3 {
        font-size: 248px;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
    }
}

.jobsContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.jobList {
    display: flex !important;
    flex-direction: column;
    flex: 1;
    gap: 32px;
    margin: 14px 0;

    @media (max-width: 550px) {
        margin: 0;
    }

    li {
        display: flex;
        list-style: none;
        margin: 0 12px;

        @media (max-width: 550px) {
            margin: 0;
        }

        & > a {
            width: 100%;
        }
    }
}

.buttonOutlined {
    padding: 12px 65px;
    border: 1px solid #2b2b2b;
    border-radius: 100px;
    background-color: transparent;
    color: #2b2b2b;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    align-self: center;
    transition: all 0.3s ease;

    @media (max-width: 750px) {
        width: 100%;
    }

    &:hover {
        background-color: #2b2b2b;
        color: #ffffff;
        cursor: pointer;
    }
}
