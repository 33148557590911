.form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
}

.root {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
}

.item {
    all: unset;
    border: 1px solid #2b2b2b;
    width: 25px;
    height: 25px;
    border-radius: 100%;

    &:hover {
        background-color: #efefef;
    }

    &:focus {
        box-shadow: 0 0 0 2px black;
    }
}

.indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: #2b2b2b;
    }
}

.label {
    // color: white;
    font-size: 15px;
    line-height: 1;
    padding-left: 15px;
}

.input {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    label {
        font-size: 16px;
        font-weight: 500;
    }

    input {
        padding: 16px;
        margin-top: 8px;
        border: 1px solid #2b2b2b;
        border-radius: 20px;
        font-size: 16px;
    }
}
