.job {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    border: none;
    border-radius: 30px;
    color: black;
    text-decoration: none;

    @media (max-width: 950px) {
        padding: 24px;
    }

    &:hover .background {
        transform: scale3d(1.01, 1.02, 1);
        box-shadow: 0 0 17px rgba(0, 0, 0, 0.1);
    }

    &:hover .jobApply span {
        transform: translateX(15px);

        &::before {
            width: 17px;
            opacity: 1;
            transform: translate3d(-2px, 14.5px, 0);
        }
    }
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background-color: #f9f9f9;
    transition: all 0.4s ease;
    border: 1px solid #707070;
}

.jobHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    z-index: 2;
}

.jobTitle {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h3 {
        font-size: 24px;
        font-weight: 700;
        white-space: pre-wrap;
        word-break: keep-all;
    }
}

.jobTags {
    display: flex;
    gap: 18px;
    margin-top: 8px;

    div {
        padding: 4px 8px;
        border-radius: 5px;
        color: white;
        font-size: 014px;
        font-weight: 600;
    }

    div:first-of-type {
        background-color: #2eb67d;
    }
    div:nth-of-type(2) {
        background-color: #9f2589;
    }
}

.jobApply {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-shrink: 0;
    z-index: 2;
    transition: all 0.4s ease;

    span {
        position: relative;
        transition: all 0.4s ease;

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            height: 2px;
            width: 0px;
            background-color: #2b2b2b;
            transform: translate3d(-2px, 14.5px, 0);
            margin-right: 8px;
            opacity: 0;
            transition: all 0.4s ease;
        }

        svg {
            height: 24px;
            width: 24px;
            margin-top: 4px;
        }
    }

    @media (max-width: 650px) {
        display: none;
    }
}

.jobDetails {
    display: flex;
    gap: 24px;
    font-size: 18px;
    z-index: 2;

    @media (max-width: 650px) {
        flex-direction: column;
        gap: 12px;
    }

    div {
        display: flex;
        gap: 8px;
        align-items: center;
        white-space: pre-wrap;
        word-break: keep-all;

        svg {
            flex-shrink: 0;
        }
    }
}

.hideDesktop {
    display: none;

    @media (max-width: 650px) {
        display: flex;
    }
}
