.back {
    display: flex;
    align-items: center;
    justify-self: start;
    gap: 8px;
    background-color: transparent;
    border: none;
    color: #000;
    padding: 0;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &:hover {
        cursor: pointer;
    }
}
