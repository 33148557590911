@import './../../Style/theme.scss';

.Button {
    padding: 12px 64px !important;
    background-color: $surface-grey-opposite;
    border-radius: 100px;
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    .label {
        width: max-content;
        font-size: 16px !important;
        @media (max-width: 1000px) {
            font-size: 14px !important;
        }
    }

    &.button-secondary {
        background-color: $surface-grey-opposite;

        &:hover {
            opacity: 0.85;
        }
    }

    &.button-primary {
        background-color: $surface-brand-rest-primary;

        &:hover {
            background-color: $surface-brand-overlay-primary;
        }
    }

    &.button-tertiary {
        background-color: $surface-brand-rest-tertiary;

        .label {
            color: $element-onbrand-secondary !important;
            font-weight: 700;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    &.button-white {
        background-color: $white;

        .label {
            color: $surface-grey-opposite !important;
            font-weight: 700;
        }

        &:hover {
            background-color: $black !important;

            .label {
                color: $white !important;
                font-weight: 700;
            }
        }
    }

    &.button-white-with-border {
        background-color: $white;
        border: 1px solid $surface-grey-opposite;

        .label {
            color: $surface-grey-opposite !important;
            font-weight: 700;
        }

        &:hover {
            background-color: $black !important;
            border: 1px solid $surface-grey-opposite;

            .label {
                color: $white !important;
                font-weight: 700;
            }
        }
    }

    &.button-navigation {
        background-color: transparent;
        border: 1px solid $surface-grey-disabled;

        .label {
            color: $element-greys-primary !important;
            font-weight: 700;
        }

        &:hover {
            border: 1px solid $element-brand-rest-primary;

            .button-icon {
                svg {
                    path {
                        fill: $element-brand-rest-primary;
                    }
                }
            }
        }
    }

    &.button-navigation-red {
        background-color: transparent;
        border: 1px solid $surface-grey-disabled;

        .label {
            color: $element-greys-primary !important;
            font-weight: 700;
        }

        &:hover {
            border: 1px solid $element-onerror-secondary;

            .button-icon {
                svg {
                    path {
                        fill: $element-onerror-secondary;
                    }
                }
            }
        }
    }

    &.button-borderless {
        background-color: transparent;
        border: 0;

        .label {
            color: $element-greys-primary !important;
            font-weight: 700;
        }

        &:hover {
            .label {
                color: $surface-brand-rest-primary !important;
            }
        }
    }

    &.fixed250 {
        width: 250px;
    }

    &.button-black {
        background-color: $surface-grey-opposite;
        border: 1px solid $white;

        .label {
            color: $white !important;
            font-weight: 700;
        }

        &:hover {
            background-color: $surface-grey-primary;
            border: 1px solid transparent;

            .label {
                color: $white !important;
            }
        }
    }

    &.button-transparent {
        background-color: transparent;
        border: 1px solid $white;

        .label {
            color: $white;
            font-weight: 700;
        }

        &:hover {
            background-color: $white;
            border: 1px solid transparent;

            .label {
                color: $surface-grey-opposite;
            }

            .button-icon {
                svg {
                    path {
                        fill: $surface-grey-opposite;
                    }
                }
            }
        }
    }

    .label {
        color: $white;
        font-weight: 700;
    }

    .button-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
    }

    &.-forced-color {
        &:hover {
            opacity: 0.9;
        }
    }

    &.-disabled {
        background-color: $surface-grey-disabled;
        p {
            color: $element-greys-tertiary !important;
        }

        &:hover {
            background-color: $surface-grey-disabled;
            cursor: default;
        }
    }

    &.-full-in-mobile {
        @media screen and (max-width: 950px) {
            min-width: 100%;

            &.fixed250 {
                min-width: 100%;
            }
        }
    }
}
