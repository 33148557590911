.testimonial {
    display: flex;
    flex: 1;
    justify-content: center;
    max-width: 990px;
    height: 488px;
    background-color: hsl(0, 0%, 100%);
    padding: 67px 80px;
    border-radius: 30px;
    margin: 40px 16px;

    @media (max-width: 1000px) {
        width: 85vw;
        max-width: auto;
        padding: 32px;
    }

    @media (max-width: 750px) {
        width: 90vw;
        padding: 24px;
    }
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.quoteContainer {
    position: relative;

    .quote {
        display: flex;
        flex-direction: column;
        gap: 24px;

        span {
            align-self: flex-start;
            font-family: 'Poppins';
            color: #2eb67d;
            font-style: italic;
            vertical-align: middle;
        }

        p {
            font-size: 24px;
            font-weight: 500;
            line-height: 130%;
            color: #2b2b2b;

            @media (max-width: 750px) {
                font-size: 18px;
            }
        }
    }
}

.absolute {
    position: absolute;
    top: 0px;
    left: 0px;
}

.footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 150%;

        @media (max-width: 750px) {
            font-size: 16px;
        }
    }

    h4 {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;

        @media (max-width: 750px) {
            font-size: 16px;
        }
    }
}

.media {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    height: 84px;
    width: 84px;
    overflow: hidden;

    & > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.hidden {
    visibility: hidden;
}
