@import './../../../Style/theme.scss';

$breakpoint-header-xl: 1600px;
$breakpoint-header-l: 1300px;
$breakpoint-header-mobile: 990px;

.Root {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;

    @media screen and (max-width: $breakpoint-header-mobile) {
        display: none;
    }
}

.MenuList {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    list-style: none;

    @media screen and (max-width: $breakpoint-header-xl) {
        & {
            gap: 15px;
        }
    }

    @media screen and (max-width: $breakpoint-header-l) {
        & {
            gap: 15px;
        }
    }
}

.Trigger {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    outline: none;
    user-select: none;
    line-height: 1;
    color: #000000 !important;
    padding: 0;

    & > svg {
        margin-left: 12px;
    }

    &:hover {
        cursor: pointer;
        color: $element-brand-rest-primary;
    }

    &:focus {
        // cursor: pointer;
        color: $element-brand-rest-primary;
    }
}

.Link {
    text-decoration: none;
    font-size: 15px;
    line-height: 1;
    color: #000000 !important;

    &:hover {
        cursor: pointer;
        color: $element-brand-rest-primary !important;
    }

    @media screen and (max-width: 1075px) {
        & {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 1000px) {
        & {
            font-size: 13px;
        }
    }
}

.selected {
    color: $element-brand-rest-primary !important;
}

.Content {
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: top left;
    background-color: #ffffff;
    border-radius: 6px;
    animation: all 0.3s ease;
    box-shadow: 0 17px 33px -2px rgba(28, 39, 49, 0.051);

    &[data-state='open'] {
        animation: scaleIn 200ms ease;
    }
    &[data-state='closed'] {
        animation: scaleOut 200ms ease;
    }
}

.Indicator {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 10px;
    top: 100%;
    overflow: hidden;
    z-index: 1;
    transition: width, transform 250ms ease;
    &[data-state='visible'] {
        animation: fadeIn 200ms ease;
    }
    &[data-state='hidden'] {
        animation: fadeOut 200ms ease;
    }
}

.List {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: 0;
    padding: 16px;
    list-style: none;
    min-width: 300px;
}

.ListItemLink {
    cursor: pointer;
    display: block;
    outline: none;
    text-decoration: none;
    padding: 0 16px;
    border-radius: 6px;
    font-size: 15px;
    line-height: 1;
    color: #000000 !important;

    &:hover {
        color: $element-brand-rest-primary !important;
    }

    &:focus {
        color: $element-brand-rest-primary !important;
    }
}

.CaretDown {
    width: 12px;
    position: relative;
    transition: transform 250ms ease;

    [data-state='open'] > & {
        transform: rotate(-180deg);
    }
}

@keyframes scaleIn {
    from {
        opacity: 0;
        transform: rotateX(-30deg) scale(0.9);
    }
    to {
        opacity: 1;
        transform: rotateX(0deg) scale(1);
    }
}

@keyframes scaleOut {
    from {
        opacity: 1;
        transform: rotateX(0deg) scale(1);
    }
    to {
        opacity: 0;
        transform: rotateX(-10deg) scale(0.95);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
