.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100svh - 80px);
    padding-top: 120px;
}

.wrapper {
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1200px;
    padding: 24px;
    gap: 48px;

    a,
    button {
        justify-self: flex-start;
    }
}

.cards {
    display: flex;
    gap: 36px;
}

.phaseCard {
    position: relative;
    display: flex;
    width: min(100%, 521px);
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 30px;
    border: 1px solid transparent;
}

.inactive {
    opacity: 0.62;
}

.active {
    border: 1px solid rgba(0, 0, 0, 0.25);
    background-color: #fff;
}

.overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(43, 43, 43, 0.39);
    border-radius: 30px;
}

.cardContent {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h4 {
        font-family: Poppins;
        font-weight: 600;
        padding-bottom: 8px;
    }
}

.emailUs {
    text-decoration: none;
    color: inherit;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardFooter {
    display: flex;
    align-items: center;
    gap: 24px;
}

.progress {
    background-color: #ebebeb;
    height: 8px;
    width: 150px;
    border-radius: 22px;

    .progressBar {
        background-color: #2eb67d;
        height: 100%;
        border-radius: 22px;
    }
}

.tag {
    border-radius: 4px;
    background: #ebebeb;
    padding: 6px 11px;

    p {
        font-family: Poppins;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 16.5px */
        text-transform: uppercase;
    }
}
