.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // aspect-ratio: 16/9;
    min-width: 100vw;
    height: 45vw;
    min-height: 650px;
    max-height: 100vh;
    background-position: center;

    @media (max-width: 850px) {
        height: calc(100vh - 80px);
        max-height: 900px;
    }

    @media (max-width: 550px) {
        max-height: 620px;
    }
}

.heroImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contentOverlay {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10;

    & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        gap: 24px;
        max-width: 768px;
        padding: 200px 32px;

        @media (max-width: 550px) {
            padding: 130px 32px;
        }

        h1 {
            font-size: 48px;
            font-weight: 500;
            text-align: center;
            line-height: normal;
            font-family: 'Poppins', sans-serif;
            color: #ffffff;

            @media (max-width: 1000px) {
                font-size: 40px;
            }

            @media (max-width: 550px) {
                font-size: 32px;
            }
        }

        p {
            color: #ffffff;
        }
    }
}

.link {
    color: #ffffff;
    text-decoration: underline;
}
