.main {
    padding-top: 80px;

    * {
        box-sizing: border-box;
    }

    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    height: calc(100svh - 80px);

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }
}
