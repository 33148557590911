@import './../../../Style/theme.scss';

$breakpoint-header-mobile: 990px;

.LayoutHeaderMobile {
    display: none;
    height: 100vh;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgb(255, 255, 255, 0.97);
    backdrop-filter: blur(500px);

    @media screen and (max-width: $breakpoint-header-mobile) {
        display: block;
    }

    a {
        text-decoration: none;
        color: $black;
    }

    .menu-burger-close {
        margin-left: auto;
        width: 32px;
        height: 32px;
        margin-top: 25px;
        margin-right: 10px;
    }

    .mobile-nav-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobile-nav-content {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;

        .content-actions {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            list-style-type: none;
            width: 250px;

            .container-header-select-language {
                display: flex;
                justify-content: center;

                .HeaderSelectLanguage {
                    background-position-y: 5px !important;
                }
            }
            .link {
                text-align: center;
                cursor: pointer;
            }

            .navigation-title {
                text-align: center;
                font-weight: 500;
            }

            .navigation-one-line {
                display: flex;
                gap: 10px;
                justify-content: center;
            }

            .sub-navigation-title {
                display: flex;
                justify-content: center;
                margin-top: 15px;
                cursor: pointer;
                gap: 10px;

                &.subitem-disabled {
                    cursor: not-allowed;
                    color: $surface-grey-primary;
                }
            }

            .nav-item-selected {
                color: $element-brand-rest-primary;
            }

            .separator {
                margin-top: 30px;
                margin-bottom: 30px;
                background-color: $surface-grey-tertiary;
                height: 1px;
                width: 100%;

                @media screen and (max-height: 560px) {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            .ButtonPartnerAccessDashboard {
                .button {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .cta-login {
                margin: 23px auto;
                text-align: center;
            }

            .ButtonDropDownModal {
                .button {
                    width: auto;
                }

                .button-arrow {
                    transform: rotateZ(-90deg);
                }
            }

            .container-LogoBrandSmall {
                margin-bottom: 40px;
                margin-left: auto;
                margin-right: auto;

                @media screen and (max-height: 560px) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .mobile-nav-partner-access {
        padding: 0px 16px;
        .back-content {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .cards-partner-action {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            margin-top: 10px;
            background-color: $white;
            position: relative;

            a {
                text-decoration: none;
            }

            .card-partner-action {
                border: 1px solid $surface-grey-disabled;
                padding: 10px 20px;
                border-radius: 10px;
                text-align: center;
                position: relative;

                p {
                    color: $surface-grey-secondary;

                    &.-isLock {
                        &.deprecated-text-title-medium-bold {
                            color: $surface-grey-opposite;
                        }
                        &.text-body-small {
                            color: $element-greys-secondary;
                        }
                    }
                }

                .lock {
                    position: absolute;
                    right: 10px;
                    top: 7px;
                }
            }

            .blur-effect-action {
                display: flex;
                justify-content: center;
                align-items: center;

                &.-is-not-approval {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    backdrop-filter: blur(5px);
                }
            }
        }

        .row-cta {
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex: 1 1 0;
            margin-top: 15px;

            > button {
                flex-grow: 1;
                flex-basis: 100%;
            }

            .button-logout {
                .deprecated-text-title-medium-bold {
                    color: $surface-error-rest-primary !important;
                }
            }
        }
    }
}
