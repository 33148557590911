.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100svh - 80px);
    padding: 120px 0;
}

.wrapper {
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1200px;
    padding: 0 24px;
    gap: 48px;

    a,
    button {
        justify-self: flex-start;
    }
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
}

.placeholder {
    height: 400px;
    width: 100%;
    border-radius: 30px;
    background-color: #8f8f8f;
}

.left {
    display: grid;
    gap: 48px;

    & > div {
        h4 {
            color: #2eb67d;
            text-transform: uppercase;
        }

        h1 {
            padding-bottom: 16px;
        }
    }
}

.steps {
    display: grid;
    gap: 24px;

    .step {
        position: relative;
        display: flex;
        align-items: center;
        gap: 16px;
        list-style: none;

        &:not(:last-child) {
            &::before {
                content: '';
                position: absolute;
                width: 2px;
                height: 100%;
                transform: translate(15px, 100%);
                background-color: #9a9a9a;
            }
        }

        span {
            width: 32px;
            height: 32px;
            flex-shrink: 0;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border: 2px solid #9a9a9a;
            color: #9a9a9a;
        }
    }

    .inProgress {
        &:not(:last-child) {
            &::before {
                background-color: #2b2b2b;
            }
        }

        p {
            font-weight: 600;
        }

        span {
            background-color: #2b2b2b;
            border-color: #2b2b2b;
            color: #ffffff;
        }
    }

    .completed {
        &:not(:last-child) {
            &::before {
                background-color: #2b2b2b;
            }
        }

        p {
            position: relative;
            color: #9a9a9a;

            &::before {
                position: absolute;
                content: '';
                width: 100%;
                height: 2px;
                background-color: #9a9a9a;
                top: 50%;
            }
        }

        span {
            background-color: #2eb67d;
            border-color: #2eb67d;
            color: #ffffff;
        }
    }
}

.testingImageContainer {
    width: 100%;

    video {
        border-radius: 20px;
    }
}

.videoContainer {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        // .constrols {
        //     opacity: 1;
        // }
    }

    video {
        height: auto;
        width: 100%;
        border-radius: 30px;
    }
}

.testingImageText {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    &Header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin-bottom: 16px;

        h4 {
            color: #2b2b2b;
            text-align: center;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
        }
    }

    p {
        max-width: 324px;
    }
}

.qrCodeContainer {
    display: flex;
    gap: 32px;
    align-items: center;

    img {
        width: 150px;
        height: 150px;
    }

    p {
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
    }
}
