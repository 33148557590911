.textField {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.label {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.input {
    display: flex;
    padding: 12px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid rgba(43, 43, 43, 0.5);
    width: 100%;

    // text
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;

    &:focus-visible {
        outline: none;
        /* custom focus styles */
        border: 1px solid #2b2b2b;
    }
}

.errorInput {
    border: 1px solid #d7382d;
}

.helperText {
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
}

.error {
    color: #d7382d;
}

.required {
    margin-left: 4px;
    color: #d7382d;
}
