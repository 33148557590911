@import '../../../../../Style/typographie.scss';
@import '../../../../../Style/theme.scss';

.Article {
    &,
    & > * {
        box-sizing: border-box;
    }

    margin-top: 80px;
    padding-bottom: 127px;

    .content-body {
        padding: 0 16px;
        max-width: 836px;
        width: 100%;
        margin: 0 auto;

        .BreadCrumb {
            margin-bottom: 32px;
            margin-top: 80px;
        }

        .container-embed {
            padding: 15px 0;
            iframe {
                width: 100%;
                height: 500px;
                border-radius: 20px;
            }
        }
    }
}
