body {
    margin: 0;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
    // remove default style of p tag
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
}

ul {
    // remove default style of ul tag
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}

// *,
// *::before,
// *::after {
//     box-sizing: border-box;
// }

#webpack-dev-server-client-overlay,
#webpack-dev-server-client-overlay-div {
    display: none !important;
}
