.BlogFutureOfFashion {
    * {
        box-sizing: border-box;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;

    .FuturOfFashionSection {
        display: flex;
        flex: 1;
        max-width: 1252px;
        margin: 60px;

        @media screen and (max-width: 1000px) {
            margin: 16px;
        }

        .box {
            width: 100%;
            max-width: 1252px;
        }
    }
}
