@import '../../../../Style/typographie.scss';
@import '../../../../Style/theme.scss';

.Banner {
    &,
    & > * {
        box-sizing: border-box;
    }

    position: relative;
    display: flex;
    align-items: flex-start;
    min-width: 100vw;
    height: 45vw;
    min-height: 650px;
    max-height: 100vh;
    background-position: center;

    @media (max-width: 850px) {
        height: calc(100vh - 80px);
        max-height: 900px;
    }

    @media (max-width: 550px) {
        max-height: 620px;
    }

    .banner-wrapper-video {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;

        .img {
            position: relative;
            overflow: hidden;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) -10.77%,
                    rgba(0, 0, 0, 0.11) 36.35%,
                    rgba(0, 0, 0, 0.2) 53.04%,
                    rgba(0, 0, 0, 0.43) 67.72%,
                    rgba(0, 0, 0, 0.65) 85.79%,
                    #000 107.06%
                ),
                linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
            overflow-y: hidden;
        }
    }

    .banner-wrapper-content-info {
        position: absolute;
        bottom: 120px;
        left: 0;
        z-index: 2;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;

        .content-info {
            max-width: 830px;
            padding: 0 16px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 32px;

            .category {
                border: 1px solid $white;
                padding: 0 19px;
                width: max-content;
                border-radius: 5px;
                text-transform: uppercase;
                border-radius: 5px;
            }

            .wrapper-time {
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }

        @media (max-width: 1000px) {
            bottom: 80px;
        }
    }

    .date,
    .author {
        @include body-large-mixin;
        color: $element-greys-secondary;
    }

    .date {
        margin-bottom: 20px;
    }

    .author {
        margin-bottom: 31px;
    }

    .banner-wrapper-icon-share {
        display: flex;
        align-items: center;
        gap: 24px;

        > * {
            cursor: pointer;
        }

        button {
            border: none;
            background-color: transparent;
            padding: 0;
            margin: 0;
        }
    }
}
