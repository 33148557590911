.sectionBackground {
    background-color: #ffffff;
}

.container {
    padding: 0 16px;
}

.headerWrapper {
    max-width: 1280px;
    margin: 0 auto;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 8px;
}

.card {
    height: 290px;
    width: 403px;

    @media (max-width: 786px) {
        height: 308px;
        width: 330px;
    }
}

.dotsList {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    list-style: none;

    li {
        height: auto;
        width: auto;
        margin: 0;
    }

    li button::before {
        // font-size: 8px;
        color: transparent !important;
    }
}

.carouselContainer {
    position: relative;
    max-width: 1320px;
    margin: 0 auto;

    @media (max-width: 1320px) {
        width: 100vw;
        max-width: auto;
    }

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        width: 200px;
        height: 350px;
        background: linear-gradient(90deg, rgba(249, 249, 249, 0) -5.08%, #ffffff 50.12%);
        z-index: 2;

        @media (max-width: 750px) {
            display: none;
        }
    }
}

.controlsContainer {
    display: flex;
    justify-content: center;

    @media (max-width: 1320px) {
        width: 100vw;
        padding: 0 16px;
    }
}

.carouselControls {
    display: flex;
    width: 100vw;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin-top: 16px;

    @media (max-width: 1320px) {
        padding: 0;
    }

    @media (max-width: 750px) {
    }
}
