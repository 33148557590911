.overlay {
    background-color: rgba(0, 0, 0, 0.45);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 1000;
}

.content {
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 4px 30px 0px rgba(43, 43, 43, 0.28);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 1000;

    &:focus {
        outline: none;
    }
}

.close {
    position: absolute;
    top: 16px;
    right: 16px;
    background: #fff;
    width: 50px;
    height: 50px;
    border: none;
    align-content: center;
    justify-content: center;
    border-radius: 100%;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes contentShow {
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}
