.trigger {
    all: unset;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #777777;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 1;
    background-color: #ffffff;
    color: #2b2b2b;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        border: 1px solid #2b2b2b;
    }

    &[data-state='open'] {
        border-radius: 8px 8px 0 0;

        .icon {
            transform: rotate(180deg);
        }
    }
}

.icon {
    color: #2b2b2b;
    left: 16px;
    transition: all 0.3s ease-in-out;
}

.content {
    overflow: hidden;
    width: 100%;
    background-color: white;
    border-radius: 0 0 8px 8px;
    width: var(--radix-select-trigger-width);
    border: 1px solid #777777;
    border-top: none;
    transform: translateY(-1px);
    z-index: 10;
}

.item {
    font-size: 16px;
    line-height: 1;
    display: flex;
    align-items: center;
    height: 25px;
    padding: 16px 16px 16px 48px;
    position: relative;
    user-select: none;
    animation: all 0.2s ease;
    background-color: white;

    &[data-highlighted] {
        outline: none;
        background-color: #2eb67d1a;
    }

    &:hover {
        cursor: pointer;
    }
}

.separator {
    height: 1px;
    background-color: #e5e5e6;
}

.itemIndicator {
    position: absolute;
    left: 16px;
    width: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
