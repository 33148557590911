.section {
    background: linear-gradient(135deg, #343434 0%, #121212 99.94%);
}

.container {
    max-width: calc(1280px + 24px + 24px);
    margin: 0 auto;
    padding: 0 24px;
}

.testimonialSection {
    box-sizing: border-box;
    overflow: hidden;
    padding-bottom: 60px;

    * {
        box-sizing: border-box;
    }

    @media (max-width: 550px) {
        flex-direction: column;
        gap: 24px;
    }
}

.testimonialSectionWrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 0 auto;
}

.controls {
    max-width: '990px';
    margin: '0 24px';
}

.absolute {
    position: absolute;
    top: 0px;
    left: 0px;
}

.hidden {
    visibility: hidden;
}

.dotsList {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    list-style: none;

    li {
        height: auto;
        width: auto;
        margin: 0;
    }

    li button::before {
        // font-size: 8px;
        color: transparent !important;
    }
}

.carouselControlsContainer {
    display: flex;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
    max-width: 990px;
    margin: 0 24px;
    box-sizing: border-box;

    @media (max-width: 1000px) {
        width: 85vw;
        max-width: none;
    }

    @media (max-width: 750px) {
        width: 90vw;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    border-top: 1px solid #696969;
    padding-top: 60px;
}

.icon {
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 20px;
    justify-self: flex-start;
    margin-bottom: 20px;
}
