@import './../../../../Style/theme.scss';

.VogueSection {
    display: grid;
    position: relative;
    width: 100vw;
    height: 650px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        height: auto;
    }

    & > * {
        grid-column: 1;
        grid-row: 1;
    }

    & * {
        box-sizing: border-box;
    }

    .vogue-cover {
        position: absolute;
        visibility: visible;
        width: 100vw;
        height: 175px;
        background-color: rgba(0, 0, 0);
        opacity: 1;
        animation-duration: 1s;

        @media screen and (max-width: 600px) {
            visibility: hidden;
        }
    }

    .top {
        top: 0;
    }

    .bottom {
        bottom: 0;
    }

    .vogue-video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .vogue-video-mobile {
        display: none;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 768px) {
            display: block;
        }
    }

    .vogue-content-overlay {
        width: 100%;
        height: 750px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #ffffffff;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 0 32px;

        & .vogue-content {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 32px;
            flex-direction: column;
            width: 550px;
            text-align: center;
            opacity: 0;

            @media screen and (max-width: 600px) {
                width: 100%;
            }

            .vogue-content-overlay-heading {
                display: flex;
                align-items: center;
                gap: 8px;

                span {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .vogue-logo {
                height: 176px;

                @media screen and (max-width: 800px) {
                    height: 120px;
                }
            }

            .vogue-description {
                display: flex;
                flex-direction: column;
                gap: 16px;

                p {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;

                    @media screen and (max-width: 800px) {
                        font-size: 16px;
                    }

                    span {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .vogue-overlay {
        visibility: visible;
        height: 750px;
        width: 100%;
        object-fit: cover;
        transform: translateY(-49px);
        opacity: 1;
        animation-duration: 1s;

        @media screen and (max-width: 600px) {
            visibility: hidden;
        }

        @media screen and (max-width: 800px) {
            height: 512px;
            transform: translateY(76px);
        }
    }

    .vogue-fade-out {
        animation-name: fadeOut;
        animation-fill-mode: forwards;
    }

    .vogue-fade-in {
        animation-name: fadeIn;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
