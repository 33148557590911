.sectionWrapper {
    display: flex;
    justify-content: center;
    padding: 100px 0;
    margin: 0 auto;
    background-color: #ffffff;

    @media (max-width: 1000px) {
        padding: 60px 0;
    }
}

.sectionContainer {
    position: relative;
    max-width: 1200px;
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 80px;
    padding: 0 32px;

    @media (max-width: 1000px) {
        gap: 64px;
        padding: 0 24px;
    }
}

.header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 650px;

    @media (max-width: 750px) {
        flex-direction: column;
        gap: 12px;
    }

    & > div {
        flex: 1;

        & > h2 {
            display: flex;
            flex-direction: column;
        }
    }
}

.benefits {
    display: flex;
    gap: 16px;

    @media (max-width: 750px) {
        flex-direction: column;
        gap: 32px;
    }

    & > div {
        flex: 1;
        border-left: 2px solid rgba(43, 43, 43, 0.5);
        padding-left: 32px;

        @media (max-width: 1000px) {
            padding-left: 24px;
            padding-top: 0;
        }

        & > p {
            font-family: 'Poppins', sans-serif;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;

            @media (max-width: 1000px) {
                font-size: 16px;
            }
        }
    }
}

.benefitsHeader {
    font-size: 42px;
    font-weight: 600;
    line-height: 57.6px;
    margin-bottom: 30px;
    background: -webkit-linear-gradient(0.9turn, #399da9, #34a797, #2fb481, #2eb67d);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 1000px) {
        font-size: 30px;
        line-height: 32px;
    }
}

.benefitsDetails {
    display: flex;
    align-items: center;
    gap: 16px;

    p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;

        @media (max-width: 1000px) {
            font-size: 14px;
            line-height: normal;
        }
    }

    svg {
        align-self: flex-start;
        flex-shrink: 0;
    }
}

.plus {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 550px) {
        gap: 16px;
        flex-direction: column;
    }

    h3 {
        color: #2b2b2b;
        font-family: 'Poppins' sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 205.714%;
        text-transform: capitalize;

        @media (max-width: 950px) {
            gap: 16px;
        }
    }

    & > div {
        display: flex;
        flex: 1;
        gap: 24px;

        & > div {
            flex: 1;

            & > ul {
                flex: 1;
            }
        }

        @media (max-width: 800px) {
            gap: 16px;
            flex-direction: column;
        }
    }
}

.plusBenefitsList {
    display: flex;
    flex-direction: column;
    gap: 16px;

    li {
        display: flex;
        list-style: none;
        gap: 24px;

        svg {
            flex-shrink: 0;
            align-self: flex-start;
        }
    }
}
