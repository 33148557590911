.container {
    display: none;

    @media (max-width: 850px) {
        display: block;
    }
}

.contentWrapper {
    padding: 120px 16px;
    width: min(100%, 512px + 32px);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 16px;
}
