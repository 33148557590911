.section {
    padding: 80px 0;
    background: radial-gradient(114.42% 101.5% at 50% 0%, #121212 0%, #333 100%), #2b2b2b;
}

.container {
    width: min(100%, calc(1280px - 48px));
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    padding: 0 24px;

    @media (max-width: 768px) {
        padding: 0;
    }

    h2 {
        padding-bottom: 40px;

        @media (max-width: 768px) {
            padding: 0 16px;
            padding-bottom: 21px;
        }

        span {
            font-weight: 600;
        }
    }

    p {
        padding-top: 40px;

        @media (max-width: 768px) {
            padding: 0 16px;
            padding-top: 21px;
        }

        span {
            font-weight: 600;
        }
    }
}

.image {
    height: auto;
    width: 1300px;
    object-fit: cover;
    z-index: 1;

    @media (max-width: 1300px) {
        min-height: 485px;
        width: 100vw;
    }
}

.imageContainer {
    position: relative;
    display: flex;
    justify-content: center;
}

.blurOverlay {
    position: absolute;
    height: 100%;
    width: 100vw;
    background: radial-gradient(
        188.78% 167.47% at 50% -41.89%,
        rgba(18, 18, 18, 0.1) 0%,
        rgba(51, 51, 51, 0.1) 100%
    );
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    transform: translate3d(0, 0, 0);
    z-index: 1;
}

.blobMobile {
    position: absolute;
    inset: 0;
    display: flex;
    visibility: hidden;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 2;

    @media (max-width: 768px) {
        visibility: visible;
    }
}

.blobDesktop {
    visibility: visible;
    position: absolute;
    pointer-events: none;
    z-index: 2;
    opacity: 0;

    @media (max-width: 768px) {
        visibility: hidden;
    }
}
