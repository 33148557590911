@import '../../Style/theme.scss';

@mixin base {
    display: flex;
    padding: 12px 64px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 8px;
    border-width: 1px;
    text-decoration: none;
    box-sizing: border-box;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

    flex-shrink: 0;

    &:hover {
        cursor: pointer;
    }
}

@mixin contained($text, $background, $hover) {
    @include base;
    background-color: $background;
    color: $text;
    text-decoration: none;

    &:hover {
        background-color: $hover;
    }

    &:disabled {
        opacity: 0.25;
    }
}

.primaryContained {
    @include contained($white, $surface-brand-rest-primary, #1c754f);
}

.secondaryContained {
    @include contained($white, $surface-grey-opposite, $element-greys-secondary);
}

@mixin outlined($text, $background) {
    @include base;
    background-color: $text;
    color: $background;
    border: 1px solid $background;
    text-decoration: none;

    &:hover {
        background-color: $background;
        color: $text;
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.5;
    }
}

.primaryOutlined {
    @include outlined($white, $surface-brand-rest-primary);
}

.secondaryOutlined {
    @include outlined($white, $surface-grey-opposite);
}

.text {
    @include base;
    padding: 12px 0px;
    background-color: transparent;
    color: $surface-grey-opposite;
    border: none;
    text-decoration: none;

    &:hover {
        color: $surface-brand-rest-primary;
        cursor: pointer;
    }

    &:disabled {
        background-color: $surface-grey-secondary;
        opacity: 0.5;
    }
}

.rounded {
    border-radius: 100px;
}
